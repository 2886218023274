import { render, staticRenderFns } from "./historyRequest.vue?vue&type=template&id=250471ba&scoped=true&"
import script from "./historyRequest.vue?vue&type=script&lang=js&"
export * from "./historyRequest.vue?vue&type=script&lang=js&"
import style0 from "./historyRequest.vue?vue&type=style&index=0&id=250471ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "250471ba",
  null
  
)

export default component.exports